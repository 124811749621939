
import { defineComponent, toRef } from "@vue/runtime-core";
import { nextTick } from "vue";

export default defineComponent({
  expose: ["getValue", "setValue", "switch"],
  emits: ["update:modelValue"],
  name: "SeInput",
  data() {
    return {
      editable: null as Boolean | null,
    };
  },
  props: {
    modelValue: {},
    value: {},
    edit: Boolean,
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onClick() {
      if (this.auto) {
        this.switch();
      }
    },
    switch() {
      this.editable = !this.editable;
    },
    getValue(): any {
      let val = this.$el.childNodes[0].value;

      if (val.trim() == "" && this.numberOnly) {
        return 0;
      } else {
        return val;
      }
    },
    setValue(value: string): void {
      this.$el.childNodes[0].value = value;
    },
    onInput($event: any) {
      var value = $event.target.value;
      this.$emit("update:modelValue", value);
    },
  },
});
