import { defineStore } from "pinia";
import User, { Role } from "@/model/User";
import Store from "@/model/Store";
import Database from "@/model/Database";
import Probe from "@/model/Probe";
import Room from "@/model/Room";
import * as StoreManager from "@/firebase/StoreManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getUserManager } from "@/firebase/UserManager";
import { getState as getAppState } from "@/pinia/AppState";
import { getStoreManager } from "@/firebase/StoreManager";
import { getProbesManager } from "@/firebase/ProbeManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import Tag from "@/model/Tag";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";

interface StoreState {
  selectedStore: Store | null;
  initialStore: Store | null;
  currentDatabase: Database | null;
  role: Role | null;
  databases: Database[] | null;
  owners: User[] | null;
  selectedDatabase: Database | null;
  probes: Probe[];
  tags: Tag[];
  kooveaStickers: KooveaSticker[];
  selectedSticker: KooveaSticker | null;
}

export const getStoreState = defineStore("storeState", {
  state: (): StoreState => {
    return {
      initialStore: null,
      selectedStore: null,
      currentDatabase: null,
      role: null,
      databases: null,
      owners: null,
      selectedDatabase: null,
      probes: [],
      tags: [],
      kooveaStickers: [],
      selectedSticker: null,
    };
  },
  actions: {
    set(store: Store) {
      this.probes = [];
      this.initialStore = store;
      this.selectedStore = Store.clone(store);
      this.selectedHub = null;
    },
    async setup() {
      this.kooveaStickers = null;

      let databaseManager = getDatabaseManager();

      await databaseManager.initialize();
      this.databases = databaseManager
        .getDatabases()
        .filter(
          (x) =>
            x.distributor != null &&
            x.distributor.id == this.selectedStore.distributor.id
        );

      this.role = getAppState().userData!.role as Role;

      this.owners = await getUserManager().getStoreOwners(this.selectedStore!);

      if (this.selectedStore!.database != null) {
        this.currentDatabase =
          await getDatabaseManager().getDatabaseByReference(
            this.selectedStore!.database
          );

        this.tags = await getDatabaseManager().getStoreTags(
          this.currentDatabase
        );
      } else {
        this.currentDatabase = null;
      }

      try {
        this.probes = await getProbesManager().getProbeOfStores(
          this.selectedStore?.ref.id!
        );

        if (this.selectedStore.distributor) {
          this.kooveaStickers = await SecureatServerApi.getKooveaStickers(
            this.selectedStore.distributor.id,
            this.selectedStore.ref.id
          );

          if (this.selectedStore.koovea_sticker != null) {
            let sticker = this.kooveaStickers.find(
              (x) => x.id == this.selectedStore.koovea_sticker
            );

            if (sticker) {
              this.selectedSticker = sticker;
            } else {
              console.warn(
                "Hub " + this.selectedStore.koovea_sticker + " not found."
              );
            }
          } else {
            this.selectedSticker = null;
          }
        }
      } catch {
        console.warn("Unable to reach server.");
        this.probes = [];
      }
    },
  },
});
