import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-83efc630"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeCheckBox = _resolveComponent("SeCheckBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_SeCheckBox, {
        key: index,
        ref_for: true,
        ref: index.toString()
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getLabel(item)), 1)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}