
import { defineComponent } from "vue";
import NavHeader from "@/components/nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import * as Lang from "@/i18n/lang";
import Database from "@/model/Database";
import TrainingList from "@/components/trainings/TrainingList.vue";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import { getTrainingState } from "@/pinia/trainings/TrainingState";
import { getTrainingManager } from "@/firebase/TrainingManager";
import { getTrainingsState } from "@/pinia/trainings/TrainingsState";
import { TrainingType } from "@/model/Trainings/TrainingType";
export default defineComponent({
  components: {
    NavHeader,
    TrainingList,
  },
  mounted() {
    this.state.selectedDatabase = null;
  },
  methods: {
    addTraining() {
      getTrainingState().set(this.state.selectedDatabase!, null);
      getState().navigate("training");
    },
    async onDatabaseChanged(database: Database) {
      this.state.trainings = await getTrainingManager().getTrainings(
        getAppState().userData,
        database
      );

      if (database != null && this.userRole == Role.Distributor) {
        this.state.trainings = this.state.trainings.filter(
          (training) => training.type == TrainingType.database
        );
      }
    },
  },
  async setup() {
    let state = getTrainingsState();

    let userRole = getAppState().userData.role;
    await state.setup();

    if (userRole != Role.Manager) {
      state.trainings = await getTrainingManager().getTrainings(
        getAppState().userData
      );
    }

    return {
      state: state,
      userRole: userRole,
      Role: Role,
    };
  },
  data() {
    return { Lang: Lang };
  },
});
