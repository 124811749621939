
import * as Lang from "@/i18n/lang";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as ModuleManager from "@/firebase/ModuleManager";
import NavHeader from "@/components/nav/NavPanel.vue";
import ElementList from "@/components/databases/ElementList.vue";
import TaskConfigurationList from "@/components/databases/TaskConfigurationList.vue";
import SearchBar from "@/components/global/SearchBar.vue";
import SeTab from "@/components/global/SeTab.vue";
import Distributor from "@/model/Distributor";
import User from "@/model/User";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent } from "vue";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { getUserManager } from "@/firebase/UserManager";
import { nextTick } from "vue";
import { Role } from "@/model/User";
import { getDatabaseState } from "@/pinia/databases/DatabaseState";
import { getDatabaseTaskConfigurationState } from "@/pinia/tasks/DatabaseTaskConfigurationState";
import { getDatabaseElementState } from "@/pinia/elements/DatabaseElementState";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import * as UploadManager from "@/model/Uploads/UploadManager";
import ToggleSwitch from "@/components/global/ToggleSwitch.vue";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import EmployeeRole from "@/model/EmployeeRole";
import Employee from "@/model/Employee";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import SeFadeButton from "@/components/global/SeFadeButton.vue";
import Tag from "@/model/Tag";
import { Upload } from "tus-js-client";
import DatabaseShopItem from "@/model/Shop/DatabaseShopItem";
import { getShopManager } from "@/firebase/ShopManager";
import DatabaseShop from "@/components/shop/DatabaseShop.vue";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getBulkElementState } from "@/pinia/elements/BulkDatabaseElementState";
import { getBulkDatabaseTaskState } from "@/pinia/tasks/BulkDatabaseTaskState";
import { ExtensionEnum } from "@/model/ExtensionEnum";

export default defineComponent({
  components: {
    ElementList,
    TaskConfigurationList,
    DatabaseShop,
  },

  methods: {
    openModulesModal() {
      var refs = <any>this.$refs;

      refs.moduleModal.open();
    },
    async saveTags() {
      var refs = <any>this.$refs;

      if (refs.tagBtn.isLoading()) {
        return;
      }

      refs.tagBtn.toggleLoading(true);

      for (let tag of this.state.tags) {
        await tag.set();
      }
      refs.tagBtn.toggleLoading(false);
      refs.tagModal.close();
    },
    getLabelFromI18N(module: String) {
      return Lang.getI18N(module.toString());
    },
    async openShop() {
      var refs = <any>this.$refs;
      this.state.shopItems = await getShopManager().getDatabaseShop(
        this.state.database!
      );
      refs.shopModal.open();
    },
    openBulk(index: number) {
      if (index == 0) {
        getBulkElementState().set(this.state.database!);
        getNavigationState().navigate("bulkDatabaseElement");
      } else {
        getBulkDatabaseTaskState().set(this.state.database!);
        getNavigationState().navigate("bulkDatabaseTask");
      }
    },
    async addTag() {
      this.state.tags.push(
        new Tag(
          doc(
            collection(
              Firebase.firestore,
              this.state.database!.ref.path,
              "store_tags"
            )
          ),
          ""
        )
      );
    },
    openTagModal() {
      var refs = <any>this.$refs;
      refs.tagModal.open();
    },
    addRole() {
      this.state.employee_roles.push(
        new EmployeeRole(
          doc(
            collection(
              Firebase.firestore,
              this.state.database!.ref.path,
              "roles"
            )
          ),
          "",
          true
        )
      );
    },
    openRoleModal() {
      var refs = <any>this.$refs;
      refs.roleModal.open();
    },
    async removeManager(manager: User) {
      let index = this.managers.indexOf(manager);
      this.managers.splice(index, 1);
      this.state.database!.managers.splice(index, 1);
    },
    openAllergensView() {
      getAllergensState().set(this.state.elements, null, this.state.database!);
      getNavigationState().navigate("allergens");
    },
    getModuleEnums() {
      var modules = ModuleManager.getModuleEnums();
      modules.splice(modules.indexOf(ModuleEnum.Action), 1);
      return modules;
    },
    getExtensionEnums() {
      return Object.values(ExtensionEnum);
    },
    onScroll(evt: any) {
      this.state.scrollY = evt.target.scrollTop;
    },
    onElementSearchChange(value: string) {
      this.state.elementSearch = value;
    },
    onTaskSearchChange(value: string) {
      this.state.taskSearch = value;
    },
    async addManager() {
      let refs = <any>this.$refs;

      if (!refs.managerForm.verify()) {
        return;
      }
      if (refs.addManagerBtn.isLoading()) {
        return;
      }

      refs.addManagerBtn.toggleLoading(true);

      await SecureatServerApi.addManager(
        refs.ownerName.getValue(),
        this.state.database.ref!.id
      );

      this.state.database = await getDatabaseManager().getDatabaseByReference(
        this.state.database.ref
      );

      this.managers = await getUserManager().getDatabaseManagers(
        this.state.database!
      );

      refs.addManagerBtn.toggleLoading(false);
    },
    async openManagersModal() {
      var refs = <any>this.$refs;
      refs.modal.open();

      this.managers = await getUserManager().getDatabaseManagers(
        this.state.database!
      );
    },
    async onIllustrationChange(event: ProgressEvent, file: File) {
      var path = "databases/" + this.state.database!.ref.id;
      var strategy = await UploadManager.createStrategy(file, path, false);
      UploadManager.addFileToUpload(strategy);

      this.pendingIllustrationPath = path;
    },

    async updateDatabase() {
      var refs = <any>this.$refs;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);

      for (let role of this.state.employee_roles) {
        await role.set();
      }

      for (let shopItem of this.state.shopItems) {
        await shopItem.set();
      }

      if (this.state.database!.distributor == null) {
        if (this.state.selectedDistributor != null) {
          this.state.database!.distributor = this.state.selectedDistributor.ref;

          this.state.selectedDistributor.databases.push(
            this.state.database.ref
          );
          await this.state.selectedDistributor.set();
        }
      }

      let indexes = refs.modulesSelect.getSelectedIndexes();

      this.state.database!.hidden_modules = [];

      for (let idStr in Object.keys(ModuleEnum)) {
        let id = parseInt(idStr);
        let module = ModuleManager.getModuleFromIndex(id);

        if (!indexes.includes(id)) {
          this.state.database!.hidden_modules.push(module.ref!);
        }
      }

      await UploadManager.applyUploads();

      if (this.pendingIllustrationPath != null) {
        this.state.database!.illustration_url = await Storage.getDownloadUrl(
          this.pendingIllustrationPath
        );
      }

      this.state.database!.hidden_extensions = [];

      let extensionIds = refs.extensionsSelect.getSelectedIndexes();

      let i = 0;
      for (let value of Object.values(ExtensionEnum)) {
        if (!extensionIds.includes(i)) {
          this.state.database!.hidden_extensions.push(value);
        }
        i++;
      }

      await this.state.database?.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    async onTabChange(selectedIndex: number) {
      this.state.tabIndex = selectedIndex;

      if (selectedIndex == 2) {
        if (this.state.database!.distributor != null) {
          // display database informations
          this.state.selectedDistributor =
            await getDistributorManager().getDistributorByReference(
              this.state.database!.distributor
            );
        } else {
          this.state.selectedDistributor = null;
        }
      }

      nextTick(() => {
        this.setDefaultTabState();
      });
    },
    openElementCreationPage() {
      getDatabaseElementState().set(null, this.state.database!);
      getState().navigate("databaseElement", false);
    },
    openTaskCreationPage() {
      getDatabaseTaskConfigurationState().set(null, this.state.database!);
      getState().navigate("taskConf", false);
    },
    displayHiddenExtensions() {
      var refs = <any>this.$refs;

      var results = [];

      var values = Object.values(ExtensionEnum);

      let i = 0;

      for (let val of values) {
        if (!this.state.database!.hidden_extensions.includes(val)) {
          results.push(i);
        }
        i++;
      }

      refs.extensionsSelect.setSelectedIndexes(results);
    },

    displayHiddenModules() {
      var refs = <any>this.$refs;
      let indexes: number[] = [];

      for (let idStr in Object.keys(ModuleEnum)) {
        let id = parseInt(idStr);

        let module = ModuleManager.getModuleFromIndex(id);

        if (
          ModuleManager.isModuleActivatedDatabase(
            this.state.database!,
            module.toEnum()
          )
        ) {
          indexes.push(id);
        }
      }
      refs.modulesSelect.setSelectedIndexes(indexes);
    },
    setDefaultTabState() {
      var refs = <any>this.$refs;
      if (this.state.tabIndex == 0) {
        refs.search.setSearch(this.state.elementSearch);
      } else if (this.state.tabIndex == 1) {
        refs.search.setSearch(this.state.taskSearch);
      } else if (this.state.tabIndex == 2) {
        this.displayHiddenModules();
        this.displayHiddenExtensions();
      }
    },
  },

  data() {
    return {
      Role: Role,
      Lang: Lang,
      managers: [] as User[],
      pendingIllustrationPath: null as string | null,
    };
  },

  async mounted() {
    var refs = <any>this.$refs;
    let tab = refs.tab;

    refs.mainLayout.scrollTop = this.state.scrollY;

    tab.changeTabIndex(this.state.tabIndex);

    nextTick(() => {
      this.setDefaultTabState();
    });
  },

  async setup() {
    let state = getDatabaseState();

    let user = getAppState().userData;

    await state.setup();

    return {
      user: user,

      state: state,
    };
  },
});
