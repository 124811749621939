
import { defineComponent } from "@vue/runtime-core";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: {
    items: null as any,
    labelFunction: null as any,
    descriptionFunction: null as any,

    addCallback: {
      type: Function,
      default: null,
      required: false,
    },
  },
  expose: [
    "getSelectedItems",
    "setSelectedIndexes",
    "getSelectedIndexes",
    "setSelectedItems",
  ],
  data() {
    return {
      Lang: Lang,
    };
  },

  mounted() {},
  methods: {
    getLabel(item: any) {
      if (this.labelFunction != null) {
        let result = this.labelFunction(item);
        return result;
      } else {
        return item;
      }
    },
    getDescription(item: any) {
      if (this.descriptionFunction != null) {
        let result = this.descriptionFunction(item);
        return result;
      } else {
        return null;
      }
    },

    setSelectedIndexes(indexes: number[]) {
      var refs = <any>this.$refs;

      for (let i = 0; i < this.items.length; i++) {
        let checked = indexes.includes(i);

        var checkbox = refs[i.toString()][0];
        checkbox.setValue(checked);
      }
    },
    setSelectedItems(selected: any[]) {
      var indexes = selected.map((x) => this.items.indexOf(x));
      this.setSelectedIndexes(indexes);
    },
    getSelectedIndexes() {
      let results = [];

      var refs = <any>this.$refs;

      for (let i = 0; i < this.items.length; i++) {
        var checkbox = refs[i.toString()][0];

        if (checkbox.getValue()) {
          results.push(i);
        }
      }

      return results;
    },
    getSelectedItems() {
      return this.getSelectedIndexes().map((x) => this.items[x]);
    },
  },
});
