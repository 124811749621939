
import { defineComponent } from "@vue/runtime-core";
import SeCheckBox from "../SeCheckBox.vue";
import * as DOMUtils from "@/utils/DOMUtils";
import { watch } from "vue";
import SeDropdownItem from "./SeDropdownItem.vue";
import { nextTick } from "vue";

export default defineComponent({
  props: {
    modelValue: {},
    titleFunc: {
      type: Function,
      required: false,
      default: (item: any) => item,
    },
    onSelectionChanged: {
      type: Function,
      required: false,
      default: null,
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTitle: {
      type: String,
      required: false,
      default: "-",
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoResizeMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  expose: [
    "toggle",
    "toggled",
    "onSelectionChangedInternal",
    "titleFunc",
    "setValue",
    "clear",
    "getValue",
  ],
  emits: ["update:modelValue"],
  data() {
    return {
      selectedValue: null as any,
    };
  },
  unmounted() {
    document.removeEventListener("click", this.onClick);
  },
  mounted() {
    document.addEventListener("click", this.onClick);

    watch(
      () => this.modelValue,
      (newValue) => {
        this.selectedValue = newValue;
      }
    );

    this.positionMenu();
    var menuContent = this.$el.querySelector(".menu-content");

    const menuRect = menuContent.getBoundingClientRect();

    if (this.autoResizeMenu) {
      let spaceBelow = Math.round(window.innerHeight - menuRect.top - 50);
      this.$el.querySelector(".menu-content").style.maxHeight =
        spaceBelow + "px";
    }

    this.setValue(this.modelValue);
  },

  methods: {
    onClick(event: MouseEvent) {
      let target = <HTMLElement>event.target;

      if (target != this.$el && !DOMUtils.isDescendantOf(target, this.$el)) {
        this.close();
      }
    },
    onSearchChange(search: string) {
      var menuContent = this.$el.querySelector(".menu-content");

      var width = menuContent.offsetWidth;

      for (let item of menuContent.childNodes) {
        if (item.tagName == "DIV") {
          if (!item.innerText.toLowerCase().includes(search.toLowerCase())) {
            item.style.display = "none";
          } else {
            item.style.display = "flex";
          }
        }
      }

      menuContent.style.width = width + "px";
    },
    getTitle(): string {
      if (this.selectedValue == null) {
        return this.defaultTitle;
      }
      return this.titleFunc(this.selectedValue);
    },
    onSelectionChangedInternal(value: any) {
      if (value == this.selectedValue) {
        if (this.isOpen()) {
          this.close();
        }

        return;
      }

      if (value == undefined) {
        value = null;
      }

      this.$emit("update:modelValue", value);
      this.selectedValue = value;

      if (this.onSelectionChanged != null) {
        this.onSelectionChanged(value);
      }
      if (this.isOpen()) {
        this.close();
      }
    },
    open() {
      this.getMenu().classList.add("menu-open");

      this.ensureMenuSize();
    },
    ensureMenuSize() {
      //  if (!this.autoResizeMenu) return;
      var menuContent = this.$el.querySelector(".menu-content");

      const menuRect = menuContent.getBoundingClientRect();

      if (this.autoResizeMenu) {
        let spaceBelow = Math.round(window.innerHeight - menuRect.top - 50);
        this.$el.querySelector(".menu-content").style.maxHeight =
          spaceBelow + "px";
      }

      if (menuContent.offsetWidth < this.$el.offsetWidth) {
        menuContent.style.width = this.$el.offsetWidth + "px";
      }
    },
    close() {
      this.getMenu().classList.remove("menu-open");
    },
    toggle() {
      this.getMenu().classList.toggle("menu-open");

      if (this.isOpen()) {
        let others = document.body.querySelectorAll(".menu-open");

        for (let other of others) {
          if (other != this.getMenu()) other.classList.remove("menu-open");
        }
        this.ensureMenuSize();
      }
    },

    isOpen() {
      return this.getMenu().classList.contains("menu-open");
    },

    getMenu(): HTMLElement {
      return this.$el.querySelector(".menu");
    },
    getDropdown(): HTMLElement {
      return this.$el.querySelector(".dropdown");
    },

    setValue(value: any) {
      this.selectedValue = value;
    },
    getValue() {
      return this.selectedValue;
    },
    clear() {
      this.selectedValue = null;
    },
    positionMenu() {
      let menu = this.getMenu();
      let dropdown = this.getDropdown();
      const dropdownRect = dropdown.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      const menuHeight = menu.offsetHeight;

      if (spaceBelow < menuHeight) {
        menu.style.bottom = `40%`;
        menu.classList.add("menu-up");
      } else {
        menu.style.bottom = `auto`;
        menu.classList.remove("menu-up");
      }
    },
  },
  components: { SeCheckBox, SeDropdownItem },
});
