
import * as Snackbars from "@/utils/Snackbars";
import * as ModuleManager from "@/firebase/ModuleManager";
import * as Lang from "@/i18n/lang";
import NavHeader from "../components/nav/NavPanel.vue";
import ElementList from "@/components/databases/ElementList.vue";
import TaskConfigurationList from "@/components/databases/TaskConfigurationList.vue";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import Element from "@/model/Elements/Element";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import SearchBar from "../components/global/SearchBar.vue";
import SeTab from "../components/global/SeTab.vue";
import { getElementManager } from "@/firebase/ElementManager";
import { getStoreManager } from "@/firebase/StoreManager";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { getStoreTaskConfigurationState } from "@/pinia/tasks/StoreTaskConfigurationState";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";
import { Role } from "@/model/User";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent, nextTick } from "vue";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import { getBulkStoreElementState } from "@/pinia/elements/BulkStoreElementState";
import { getBulkStoreTaskState } from "@/pinia/tasks/BulkStoreTaskState";
import SeEditableText from "@/components/global/SeEditableText.vue";
import EmployeeRole from "@/model/EmployeeRole";
import {
  collection,
  doc,
  DocumentReference,
  Firestore,
} from "firebase/firestore";
import Employee from "@/model/Employee";
import * as Firebase from "@/firebase/Firebase";
import { ExtensionEnum } from "@/model/ExtensionEnum";
import { getStoreLabels } from "@/firebase/LabelsManager";

export default defineComponent({
  components: {
    ElementList,
    TaskConfigurationList,
    SeCheckBox,
  },

  data() {
    let state = getAppState();

    let user = getAppState().userData;

    return {
      ExtensionEnum: ExtensionEnum,
      user: user,
      Role: Role,
    };
  },
  methods: {
    addEmployee() {
      this.state.employees.push(
        new Employee(
          doc(collection(Firebase.firestore, "employees")),
          "Nouvel ",
          "employé",
          true,
          null,
          null,
          [],
          [this.state.store.ref],
          [this.state.store.database],
          null
        )
      );
    },
    openModulesModal() {
      var refs = <any>this.$refs;
      refs.moduleModal.open();
    },
    openBulk() {
      if (this.state.tabIndex == 0) {
        getBulkStoreElementState().set(this.state.store);
        getNavigationState().navigate("bulkStoreElement");
      } else {
        getBulkStoreTaskState().set(this.state.store!);
        getNavigationState().navigate("bulkStoreTask");
      }
    },
    selectAllElements() {
      var refs = <any>this.$refs;
      let checkboxState = refs.selectAllElements.getValue();

      this.state.elements!.map((element) => {
        refs[element.ref?.id!][0].setValue(!checkboxState);
      });
    },
    selectAllTasks() {
      var refs = <any>this.$refs;
      let checkboxState = refs.selectAllTasks.getValue();

      this.state.tasks!.map((task) => {
        refs[task.ref?.id!][0].setValue(!checkboxState);
      });
    },

    getSectionEnums() {
      return [];
    },
    getLabelFromI18N(module: String) {
      return Lang.getI18N(module.toString());
    },
    onScroll(evt: any) {
      this.state.scrollY = evt.target.scrollTop;
    },
    onElementSearchChange(value: string) {
      this.state.elementSearch = value;
    },
    onTaskSearchChange(value: string) {
      this.state.taskSearch = value;
    },
    onTabChange(selectedIndex: number) {
      this.state.tabIndex = selectedIndex;
      nextTick(() => {
        this.setDefaultTabState();
      });
    },
    openElementCreationPage() {
      getStoreElementState().set(null, this.state.store!);
      getState().navigate("storeElement");
    },
    openTaskCreationPage() {
      getStoreTaskConfigurationState().set(null, this.state.store!);
      getState().navigate("storeTaskConf");
    },
    openAllergensView() {
      if (this.state.database) {
        getAllergensState().set(
          this.state.elements,
          this.state.store!,
          this.state.database
        );
        getNavigationState().navigate("allergens");
      } else {
        getAllergensState().set(this.state.elements, this.state.store!, null);
        getNavigationState().navigate("allergens");
      }
    },
    openExportModal() {
      var refs = <any>this.$refs;
      refs.exportModal.open();
    },
    async saveEmployees() {
      var refs = <any>this.$refs;
      refs.modifyBtn.toggleLoading(true);

      for (let employee of this.state.employees) {
        await employee.set();
      }

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    exportStore() {
      var refs = <any>this.$refs;

      var exportedElements: Element[] = [];

      var exportedTasks: TaskConfiguration[] = [];

      for (let element of this.state.elements!) {
        let checkBox = refs[element.ref?.id!][0];

        if (checkBox.getValue()) {
          exportedElements.push(element);
        }
      }

      for (let task of this.state.tasks!) {
        let checkBox = refs[task.ref?.id!][0];

        if (checkBox.getValue()) {
          exportedTasks.push(task);
        }
      }

      if (this.state.database !== null) {
        getStoreManager().exportStoreDatabase(
          this.state.store!,
          this.state.database,
          exportedElements,
          exportedTasks
        );
      }
    },
    async save() {
      var refs = <any>this.$refs;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);
      this.state.store!.hidden_modules = [];

      let selectedIndexes = refs.modulesSelect.getSelectedIndexes();
      let selectedModules: string[] = [];
      let availableModules = this.getModuleEnums();

      availableModules.forEach((availableModule, index) => {
        if (
          ModuleManager.isModuleActivated(this.state.store!, availableModule)
        ) {
          if (selectedIndexes.includes(index)) {
            selectedModules.push(availableModule);
          }
        }
      });

      for (let idStr in Object.keys(ModuleEnum)) {
        let id = parseInt(idStr);
        let module = ModuleManager.getModuleFromIndex(id);

        if (
          !ModuleManager.isModuleActivatedDatabase(
            this.state.database!,
            module.toEnum()
          )
        ) {
          continue;
        }

        if (!selectedModules.includes(module.key)) {
          this.state.store!.hidden_modules.push(module.ref!);
        }
      }

      this.state.store!.hidden_extensions = [];

      let extensionIds = refs.extensionsSelect.getSelectedIndexes();

      let i = 0;
      for (let value of Object.values(ExtensionEnum)) {
        if (!extensionIds.includes(i)) {
          this.state.store!.hidden_extensions.push(value);
        }
        i++;
      }

      console.log(this.state.store!.hidden_extensions);

      await this.state.store?.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    openFileInput() {
      let refs = <any>this.$refs;
      refs.fileInput.click();
    },
    canImportExport() {
      return (
        getAppState().userData?.role == Role.Admin ||
        getAppState().userData?.role == Role.Distributor
      );
    },
    async handleFileChange(event: any) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.name.endsWith(".secureat")) {
        if (this.state.database !== null) {
          let result = await getStoreManager().importStoreDatabase(
            this.state.store!,
            this.state.database,
            selectedFile
          );
          if (result) {
            Snackbars.display(Lang.getI18N("imported-successfully"));
          } else {
            Snackbars.display(Lang.getI18N("import-error"));
          }
        }
      } else {
        console.error("Please select a valid JSON file.");
      }
    },
    getModuleEnums() {
      let results = [];

      let modules = ModuleManager.getModuleEnums();

      modules.splice(modules.indexOf(ModuleEnum.Action), 1);

      for (let module of modules) {
        if (
          ModuleManager.isModuleActivatedDatabase(this.state.database!, module)
        ) {
          results.push(module);
        }
      }

      return results;
    },
    getExtensionEnums() {
      this.state.database.hidden_extensions;
      // take in account database unactive
      var values = Object.values(ExtensionEnum);

      var results = [];

      for (let value of values) {
        if (!this.state.database!.hidden_extensions.includes(value)) {
          results.push(value);
        }
      }
      return results;
    },
    displayHiddenExtensions() {
      var refs = <any>this.$refs;

      var results = [];

      var values = Object.values(ExtensionEnum);

      let i = 0;

      for (let val of values) {
        if (!this.state.store!.hidden_extensions.includes(val)) {
          results.push(i);
        }
        i++;
      }

      refs.extensionsSelect.setSelectedIndexes(results);
    },

    displayHiddenModules() {
      var refs = <any>this.$refs;

      let indexes: number[] = [];

      let availableModules = this.getModuleEnums();

      availableModules.forEach((availableModule, index) => {
        if (
          ModuleManager.isModuleActivated(this.state.store!, availableModule)
        ) {
          indexes.push(index);
        }
        refs.modulesSelect.setSelectedIndexes(indexes);
      });
    },

    setDefaultTabState() {
      var refs = <any>this.$refs;
      if (this.state.tabIndex == 0) {
        refs.search.setSearch(this.state.elementSearch);
      } else if (this.state.tabIndex == 1) {
        refs.search.setSearch(this.state.taskSearch);
      } else if (this.state.tabIndex == 3) {
        this.displayHiddenModules();
        this.displayHiddenExtensions();
      }
    },
  },

  mounted() {
    let tab = <any>this.$refs.tab;
    tab.changeTabIndex(this.state.tabIndex);

    var refs = <any>this.$refs;

    refs.mainLayout.scrollTop = this.state.scrollY;

    nextTick(() => {
      this.setDefaultTabState();
    });
  },

  async setup() {
    var state = getStoreDatabaseState();

    await state.setup();

    return {
      state: state,
      Lang: Lang,
      elementManager: getElementManager(),
    };
  },
});
