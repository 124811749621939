
import NavPanel from "@/nav/NavPanel.vue";
import NavHeader from "@/nav/NavHeader.vue";
import ToggleSwitch from "@/components/global/ToggleSwitch.vue";
import SeInput from "@/components/global/SeInput.vue";
import SeButton from "@/components/global/SeButton.vue";

import * as DatabaseManager from "@/firebase/DatabaseManager";
import { getElementManager } from "@/firebase/ElementManager";
import * as Storage from "@/firebase/Storage";
import * as Lang from "@/i18n/lang";
import * as DOMUtils from "@/utils/DOMUtils";

import SeInputFile from "@/components/global/SeInputFile.vue";
import SeFadeButton from "@/components/global/SeFadeButton.vue";
import LockedField from "@/components/global/LockedField.vue";
import SeModal from "@/components/global/SeModal.vue";
import * as Modals from "@/utils/Modals";
import { defineComponent } from "vue";
import * as LabelsManager from "@/firebase/LabelsManager";
import { getState } from "@/pinia/NavigationState";
import DatabaseModuleTab from "@/components/databases/elements/DatabaseModuleTab.vue";
import ElementType from "@/model/ElementType";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import * as Snackbars from "@/utils/Snackbars";
import StockImagePicker from "@/components/global/StockImagePicker.vue";
import * as ImageUtils from "@/utils/ImageUtils";
import { getDatabaseElementState } from "@/pinia/elements/DatabaseElementState";
import { compareObjects } from "@/utils/ObjectUtils";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as ObjectUtils from "@/utils/ObjectUtils";

export default defineComponent({
  data() {
    return {
      imageModified: false,
      manager: getElementManager(),
      labelType: "expiration",
      currentLabel: null,
      Lang: Lang,
    };
  },
  expose: ["apply"],
  async setup() {
    let state = getDatabaseElementState();

    if (state.element!.illustration_url != null) {
      state.element!.illustration_url = ImageUtils.fromImageKit(
        state.element!.illustration_url
      );
    }
    await state.setup();

    return {
      state: state!,
    };
  },
  async mounted() {
    if (!this.state.creation) {
      await this.displayElementPicture();
      await this.displayType();
    }
  },
  methods: {
    openEditTaskTypeModal(type: ElementType) {
      var refs = <any>this.$refs;

      refs.typeName.setValue(type.name);
      refs.typeModal.open(type);
    },
    closeIllustrationModal() {
      var refs = <any>this.$refs;
      refs.illustrationModal.close();
    },
    openIllustrationModal() {
      var refs = <any>this.$refs;
      refs.stockImages.search(this.state.element?.getName());
      refs.illustrationModal.open();
    },
    displayType() {
      if (this.state.element!.type == null) {
        return;
      }
      var results = this.state.types.filter(
        (x) => x.ref?.id == this.state.element!.type!.id
      );
      if (results.length > 0) {
        this.state.selectedType = results[0];
      }
    },
    onNameChange(value: any) {
      var refs = <any>this.$refs;
      refs.stockImages.search(value);
    },
    openTypeModalCreation() {
      let refs = <any>this.$refs;
      refs.typeName.setValue("");
      refs.typeModal.open();
    },
    pickIllustration() {
      DOMUtils.selectFileDialog(this.pickIllustrationFromFile);
    },

    async pickStockImageIllustration(url: string) {
      var refs = <any>this.$refs;

      let picture: any = this.$refs.picture;

      var buffer = await ImageUtils.loadImageAsArrayBuffer(url);
      var type = "image/png";

      const blob = new Blob([buffer], { type });

      ObjectUtils.blobToBase64(blob)
        .then((base64) => {
          picture.src = base64;
        })
        .catch((error) => {
          console.error("Error converting blob to Base64:", error);
        });

      const file = new File([blob], "Illustration", { type });

      this.state.element.illustration_url = url;

      this.modifyIllustration(file);

      refs.illustrationModal.close();
    },
    async pickIllustrationFromFile(e: any) {
      let picture: any = this.$refs.picture;
      let file = e.target.files[0];
      const fileReader = new FileReader();

      var instance = this;
      fileReader.addEventListener("load", function (evt: any) {
        if (!file.type.includes("image")) {
          return;
        }

        picture.src = evt.target.result;

        instance.modifyIllustration(file);
      });

      fileReader.readAsDataURL(file);
    },
    async modifyIllustration(file: any) {
      var refs = <any>this.$refs;

      let path =
        "databases/" +
        this.state.getDatabaseId() +
        "/element_pictures/" +
        this.state.element?.ref.id!;

      this.state.element!.illustration_path = path;
      this.imageModified = true;

      var strategy = await UploadManager.createStrategy(file, path, false);
      UploadManager.addFileToUpload(strategy);

      refs.illustrationModal.close();
    },

    async clone() {
      this.apply();

      let el = this.state.element!!;

      let refs = <any>this.$refs;

      if (refs.cloneBtn.isLoading()) {
        return;
      }
      refs.cloneBtn.toggleLoading(true);

      let cloned = DatabaseElement.clone(el, false);

      cloned.name += " (Copy)";

      await this.manager.createDatabaseElement(
        this.state.getDatabaseId(),
        cloned,
        this.state.selectedType
      );

      await LabelsManager.setLabels(cloned, refs.moduleTab.labels);

      let state = getState();
      state.goBack();
    },

    async displayElementPicture() {
      let picture = <any>this.$refs.picture;
      picture.src = this.state.element!.getIcon();
    },
    async createOrEditType() {
      let refs = <any>this.$refs;

      if (!refs.typeForm.verify()) {
        return;
      }

      let typeName = refs.typeName.getValue();

      if (refs.typeModal.context == null) {
        let newType = await ElementTypeManager.createDatabaseElementType(
          this.state.getDatabaseId(),
          typeName
        );

        this.state.types.push(newType);
        this.state.selectedType = newType;
      } else {
        let type = <ElementType>refs.typeModal.context;
        type.name = typeName;
        await ElementTypeManager.updateElementType(type);
      }

      refs.typeModal.close();
    },

    async apply() {
      let refs = <any>this.$refs;

      let applyBtn = refs.applyBtn;

      if (
        this.state.element!.getMinimumTemperature() != null &&
        this.state.element!.getMaximumTemperature() != null &&
        this.state.element!.getMinimumTemperature()! >
          this.state.element!.getMaximumTemperature()!
      ) {
        refs.message.innerHTML = Lang.getI18N("invalid-temperature-input");
        refs.modal.open();
        return;
      }

      if (this.state.element!.name == "") {
        refs.message.innerHTML = "Veuillez specifier un nom valide.";
        refs.modal.open();
        return;
      }

      if (applyBtn.isLoading()) {
        return;
      }

      applyBtn.toggleLoading(true);

      try {
        if (this.state.selectedType != null) {
          this.state.element!.type = this.state.selectedType.ref;
        } else {
          this.state.element!.type = null;
        }

        await UploadManager.applyUploads();
        for (let attachment of this.state.element?.attachments!) {
          await attachment.generateUrl();
        }

        if (this.imageModified) {
          this.state.element!.illustration_url = await Storage.getDownloadUrl(
            this.state.element?.illustration_path!
          );
        }

        if (this.state.creation) {
          await this.manager.createDatabaseElement(
            this.state.getDatabaseId(),
            this.state.element!,
            this.state.selectedType!
          );

          await LabelsManager.setLabels(
            this.state.element!,
            refs.moduleTab.labels
          );

          this.state.creation = false;
        } else {
          await this.manager.updateDatabaseElement(
            this.state.getDatabaseId(),
            this.state.element!
          );

          await LabelsManager.setLabels(
            this.state.element!,
            refs.moduleTab.labels
          );
        }

        await UploadManager.applyDeletions();

        this.state.changeSaved = true;

        applyBtn.toggleLoading(false);

        Snackbars.display(Lang.getI18N("saved-successfully"));
      } catch (e: any) {
        Snackbars.display(Lang.getI18N("save-error"), 3000, "error");
        applyBtn.toggleLoading(false);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.state.changeSaved) {
      next();
      return;
    }
    if (
      this.state.creation ||
      !compareObjects(this.state.initialElement, this.state.element)
    ) {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        "Oui",
        "Non"
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    SeInputFile,
    SeButton,
    SeFadeButton,
    LockedField,
    SeModal,
    DatabaseModuleTab,
    StockImagePicker,
  },
});
