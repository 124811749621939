
import { PropType, defineComponent, nextTick } from "vue";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import Element from "@/model/Elements/Element";
import * as Lang from "@/i18n/lang";
import StoreElement from "@/model/Elements/StoreElement";
import { ModuleEnum } from "@/model/ModuleEnum";

export default defineComponent({
  props: {
    validElements: Array as PropType<Element[]>,
    selectedElements: Array as PropType<Element[]>,
    module: {
      default: ModuleEnum.Temperatures,
      required: true,
    },
    store: {
      type: Boolean,
      default: false,
    },
  },

  expose: ["automatic", "getSelectedElements", "update"],
  data() {
    return {
      displayedElements: [] as Element[],
      automatic: false,
      Lang: Lang,
      previouslySelected: [] as Element[],
    };
  },

  methods: {
    displayAutomatic() {
      return this.store && this.module == ModuleEnum.Temperatures;
    },
    alreadySelected(item: Element): boolean {
      return this.selectedElements!.some(
        (element: Element) => element.getName() == item.getName()
      );
    },
    onToggleClick(value: boolean) {
      var refs = <any>this.$refs;
    },

    onSelectAllClicked() {
      var refs = <any>this.$refs;

      var flag = refs.selectAll.getValue();
      this.toggleSelectAll(flag);
    },
    toggleSelectAll(flag: boolean) {
      var refs = <any>this.$refs;
      for (let i = 0; i < this.displayedElements.length; i++) {
        var ref = refs[i.toString()][0];

        ref.setValue(flag);
      }
    },

    toggleCheckBox(item: Element) {
      var index = this.displayedElements.indexOf(item);
      var refs = <any>this.$refs;

      if (refs[index] == undefined) {
        return;
      }

      var checkbox = refs[index.toString()][0];

      checkbox.setValue(!checkbox.getValue());
    },
    getSelectedElements() {
      let results: any = [];

      let refs = <any>this.$refs;

      for (let i = 0; i < this.displayedElements.length; i++) {
        let checkbox = refs[i.toString()][0];

        var element = this.displayedElements[i];

        if (checkbox == refs.selectAll) {
          continue;
        }

        if (checkbox.getValue()) {
          results.push(element);
        }
      }

      console.log("results " + results);

      return results;
    },

    update() {
      this.onAutomaticChange(this.automatic);

      nextTick(() => {
        for (let selectedElement of this.selectedElements) {
          var index = this.displayedElements.indexOf(selectedElement);
          var ref = this.$refs[index.toString()][0];
          ref.setValue(true);
        }
      });
    },
    onAutomaticChange(val: boolean) {
      var refs = <any>this.$refs;

      if (refs.automaticSwitch == undefined) {
        this.displayedElements = this.validElements!;

        return;
      }

      if (this.store) {
        if (val) {
          var elements = this.getSelectedElements();
          this.previouslySelected = elements;

          this.displayedElements = this.validElements?.filter(
            (x) => (<StoreElement>x).probe_id != null
          )!;

          nextTick(() => {
            this.toggleSelectAll(false);
          });
        } else {
          this.displayedElements = this.validElements!;

          console.log(this.previouslySelected);

          nextTick(() => {
            this.toggleSelectAll(false);
            for (let element of this.previouslySelected) {
              var index = this.displayedElements.indexOf(element);
              refs[index.toString()][0].setValue(true);
            }
          });
        }

        refs.automaticSwitch.toggle(val, false);

        refs.selectAll.setValue(false);
      }
    },
  },
  watch: {
    validElements: {
      handler(val: Element[]) {
        this.displayedElements = val;
      },
    },
    automatic: {
      handler(val: boolean) {
        this.onAutomaticChange(val);
      },
    },
  },
});
