import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-98b94e9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (this.edit || _ctx.editable)
    ? (_openBlock(), _createElementBlock("input", {
        key: 0,
        class: "editable-input",
        ref: "textInput",
        value: _ctx.value == undefined ? _ctx.modelValue : _ctx.value,
        type: "text",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event)))
      }, null, 40, _hoisted_1))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: "text-muted"
      }, _toDisplayString(_ctx.value == undefined ? _ctx.modelValue : _ctx.value), 1))
}